import React from 'react';
import { Book, Feather, RefreshCw, Zap } from 'lucide-react';

const HomePage = () => {
  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-100 to-indigo-200 flex flex-col items-center justify-center p-4">
      <h1 className="text-4xl font-bold text-indigo-800 mb-4">Kelime Deposu</h1>
      <p className="text-xl text-indigo-600 mb-8">Türkçenin zenginliklerini keşfedin!</p>
      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 w-full max-w-3xl">
        <Button 
          color="bg-red-500 hover:bg-red-600" 
          icon={<Book />} 
          text="Deyimler" 
          link="https://deyimler.kelimedeposu.com"
        />
        <Button 
          color="bg-green-500 hover:bg-green-600" 
          icon={<Feather />} 
          text="Atasözleri" 
          link="https://atasozleri.kelimedeposu.com"
        />
        <Button 
          color="bg-yellow-500 hover:bg-yellow-600" 
          icon={<RefreshCw />} 
          text="Eş Anlamlı" 
          link="https://esanlamli.kelimedeposu.com"
        />
        <Button 
          color="bg-purple-500 hover:bg-purple-600" 
          icon={<Zap />} 
          text="Zıt Anlamlı" 
          link="https://zitanlamli.kelimedeposu.com"
        />
      </div>
    </div>
  );
};

const Button = ({ color, icon, text, link }) => {
  return (
    <a 
      href={link} 
      target="_blank" 
      rel="noopener noreferrer" 
      className={`${color} text-white rounded-lg p-6 flex flex-col items-center justify-center transition-transform transform hover:scale-105 shadow-lg`}
    >
      <div className="text-4xl mb-2">{icon}</div>
      <span className="text-xl font-semibold">{text}</span>
    </a>
  );
};

export default HomePage;